import * as actionTypes from '../constants/actionTypes';

import { updateObject } from '../../../../shared/utils/utility';

const initialState = {
  renderedDocumentsLoading: false,
  renderedDocuments: null,
  renderedDocumentVersionsLoading: false,
  renderedDocumentVersions: null
}



const fetchRenderedDocumentsStart = (state, action) => updateObject(state, { renderedDocumentsLoading: true });

const fetchRenderedDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    renderedDocumentsLoading: false,
    renderedDocuments: action.renderedDocuments
  });
}

const fetchRenderedDocumentsError = (state, action) => updateObject(state, { renderedDocumentsLoading: false });



const fetchRenderedDocumentVersionsStart = (state, action) => updateObject(state, { renderedDocumentVersionsLoading: true });

const fetchRenderedDocumentVersionsSuccess = (state, action) => {
  return updateObject(state, {
    renderedDocumentVersionsLoading: false,
    renderedDocumentVersions: action.renderedDocumentVersions
  });
}

const fetchRenderedDocumentVersionsError = (state, action) => updateObject(state, { renderedDocumentVersionsLoading: false });



const downloadRenderedDocumentVersionStart = (state, action) => updateObject(state, { downloadDocumentVersionLoading: true });

const downloadRenderedDocumentVersionSuccess = (state, action) => { return updateObject(state, { downloadDocumentVersionsLoading: false }); }

const downloadRenderedDocumentVersionError = (state, action) => updateObject(state, { downloadDocumentVersionLoading: false });



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RENDERED_DOCUMENTS_START: return fetchRenderedDocumentsStart(state, action);
    case actionTypes.FETCH_RENDERED_DOCUMENTS_SUCCESS: return fetchRenderedDocumentsSuccess(state, action);
    case actionTypes.FETCH_RENDERED_DOCUMENTS_ERROR: return fetchRenderedDocumentsError(state, action);

    case actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_START: return fetchRenderedDocumentVersionsStart(state, action);
    case actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_SUCCESS: return fetchRenderedDocumentVersionsSuccess(state, action);
    case actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_ERROR: return fetchRenderedDocumentVersionsError(state, action);

    case actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_START: return downloadRenderedDocumentVersionStart(state, action);
    case actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_SUCCESS: return downloadRenderedDocumentVersionSuccess(state, action);
    case actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_ERROR: return downloadRenderedDocumentVersionError(state, action);

    default: return state;
  }
}

export default reducer;