import { applyMiddleware, combineReducers, createStore, compose } from 'redux';

import thunk from 'redux-thunk';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as oidcReducer } from 'redux-oidc';

import renderedDocumentsReducer from '../../modules/RenderedDocuments/store/reducers/renderedDocuments';

export const history = createBrowserHistory();

export const configureStore = () => {
  const reducers = {
    router: connectRouter(history),
    oidc: oidcReducer,
    renderedDocuments: renderedDocumentsReducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  const rootReducer = combineReducers({
    ...reducers
  });

  let composeEnhancers = null;

  if (process.env.NODE_ENV === 'production') {
    composeEnhancers = compose;
  } else {
    composeEnhancers =
      typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
  }

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
  );

  return createStore(rootReducer, enhancer);
}