import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';

import GridBase from '../../shared/UI/GridBase/GridBase';
import ResourceViewer from '../../shared/UI/ResourceViewer/ResourceViewer';

import * as actions from '../../shared/store/actions';

import styles from './RenderedDocuments.module.scss';

class RenderedDocumentsTable extends GridBase {
  componentDidMount() {
    this.setState({
      sort: {
        sortField: 'Id',
        dir: 'asc'
      },
    }, () => this.loadData());
  }

  loadData() {
    const gridState = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      sort: {
        sortField: this.state.sort.sortField,
        dir: this.state.sort.dir
      },
      filter: this.state.filter
    };

    this.props.fetchRenderedDocuments(gridState);
  }

  refreshTable = () => this.loadData();

  downloadPDFDoc = (doc) => {
    this.props.downloadRenderedDocumentVersion(doc.id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = res.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+);/);
          if (fileNameMatch.length === 2)
            fileName = fileNameMatch[1];

          if (fileName) {
            fileName = fileName.replace(/"/g, '').trim();
          }
        }
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  render() {
    let columns = [
      { header: 'Received Id', id: 'receivedId', search: true, sorting: true, dataType: 'string', width: 4 },
      { header: 'Document Type', id: 'documentType', search: true, sorting: true, dataType: 'string', width: 4 },
      {
        header: 'Latest Version', id: 'version', search: false, dataType: 'string',
        accessor: d => {
          return <>
            <span style={{ marginRight: '5px' }}>V{d.version}</span>
            <Dropdown text='More' scrolling
              loading={this.props.renderedDocumentVersionsLoading && d.id === this.state.clickedMenuId}
              onOpen={() => {
                this.setState({ clickedMenuId: d.id }, () => {
                  this.props.fetchRenderedDocumentVersions(d.id);
                });
              }}>
              <Dropdown.Menu>
                {
                  this.props.renderedDocumentVersions && !this.props.renderedDocumentVersionsLoading
                    ? <>
                      {this.props.renderedDocumentVersions
                        .map(doc => <Dropdown.Item key={doc.id}
                          text={`V${doc.version} (${moment(doc.createdAt).format('MM/DD/YY hh:mm A')})`}
                          onClick={() => this.downloadPDFDoc(doc)} />)}
                    </>
                    : null
                }
              </Dropdown.Menu>
            </Dropdown>
          </>;
        }
      },
      { header: 'CreatedAt', id: 'createdAt', search: false, sorting: true, dataType: 'string', accessor: d => moment(d.createdAt).format('MM/DD/YY hh:mm A') },
    ];

    return <div className={styles.RenderedDocumentsTable}>
      <ResourceViewer
        striped={true}
        pagination={true}
        selectable={false}
        editMode={false}
        displaySearch
        customTableHeaderStyles={{ background: '#eaedef' }}
        header='Rendered Documents'
        actions={false}
        refreshTable={this.refreshTable}
        columns={columns}
        sortField={this.state.sort.sortField}
        dir={this.state.sort.dir}
        sort={this.handleSort}
        filterGrid={this.handleFilter}
        filter={this.state.filter}
        page={this.state.page}
        pageSize={this.state.pageSize}
        pageSizeChanged={this.handlePageSizeChange}
        pageChanged={this.handlePageChange}
        loading={this.props.renderedDocumentsLoading}
        data={this.props.renderedDocuments} />
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    renderedDocumentsLoading: state.renderedDocuments.renderedDocumentsLoading,
    renderedDocuments: state.renderedDocuments.renderedDocuments,
    renderedDocumentVersionsLoading: state.renderedDocuments.renderedDocumentVersionsLoading,
    renderedDocumentVersions: state.renderedDocuments.renderedDocumentVersions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRenderedDocuments: (gridState) => dispatch(actions.fetchRenderedDocuments(gridState)),
    fetchRenderedDocumentVersions: (documentId) => dispatch(actions.fetchRenderedDocumentVersions(documentId)),
    downloadRenderedDocumentVersion: (documentId) => dispatch(actions.downloadRenderedDocumentVersion(documentId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderedDocumentsTable);
