import axios from '../../../../axios/axios-api';

import * as actionTypes from '../constants/actionTypes';

const fetchRenderedDocumentsStart = () => { return { type: actionTypes.FETCH_RENDERED_DOCUMENTS_START }; }

const fetchRenderedDocumentsSuccess = (renderedDocuments) => {
  return {
    type: actionTypes.FETCH_RENDERED_DOCUMENTS_SUCCESS,
    renderedDocuments: renderedDocuments
  };
}

const fetchRenderedDocumentsError = () => { return { type: actionTypes.FETCH_RENDERED_DOCUMENTS_ERROR }; }

export const fetchRenderedDocuments = (gridState) => {
  return dispatch => {
    dispatch(fetchRenderedDocumentsStart());
    axios.post('renderedDocuments/list', gridState)
      .then(res => {
        dispatch(fetchRenderedDocumentsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchRenderedDocumentsError());
      });
  };
}

const fetchRenderedDocumentVersionsStart = () => { return { type: actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_START }; }

const fetchRenderedDocumentVersionsSuccess = (renderedDocumentVersions) => {
  return {
    type: actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_SUCCESS,
    renderedDocumentVersions: renderedDocumentVersions
  };
}

const fetchRenderedDocumentVersionsError = () => { return { type: actionTypes.FETCH_RENDERED_DOCUMENT_VERSIONS_ERROR }; }

export const fetchRenderedDocumentVersions = (documentId) => {
  return dispatch => {
    dispatch(fetchRenderedDocumentVersionsStart());
    axios.get(`renderedDocuments/versions-list?documentId=${documentId}`)
      .then(res => {
        dispatch(fetchRenderedDocumentVersionsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchRenderedDocumentVersionsError());
      });
  };
}

const downloadRenderedDocumentVersionStart = () => { return { type: actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_START }; }

const downloadRenderedDocumentVersionSuccess = () => { return { type: actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_SUCCESS }; }

const downloadRenderedDocumentVersionError = () => { return { type: actionTypes.DOWNLOAD_RENDERED_DOCUMENT_VERSION_ERROR }; }

export const downloadRenderedDocumentVersion = (documentId) => {
  return dispatch => {
    dispatch(downloadRenderedDocumentVersionStart());
    return new Promise((resolve, reject) => {
      axios.get(`/renderedDocuments/download-document-version?documentId=${documentId}`, { responseType: 'blob' })
        .then((response) => {
          if (+response.status !== 200) {
            dispatch(downloadRenderedDocumentVersionError());
            reject(new Error(response.data.errorMessage));
          }

          dispatch(downloadRenderedDocumentVersionSuccess());
          resolve(response);
        })
        .catch((error) => {
          dispatch(downloadRenderedDocumentVersionError());
          reject(error);
        });
    });
  }
}