import { Component } from 'react';

class GridBase extends Component {
  state = {
    page: 1,
    pageSize: 10,
    sort: {
      sortField: '',
      dir: 'asc'
    },
    filter: {
      filters: []
    },
    searchTimer: null
  };

  handlePageSizeChange = (event, pageSize) => {
    if (this.state.pageSize !== pageSize.value) {
      this.setState({ pageSize: pageSize.value, page: 1 }, () => this.loadData());
    }
  }

  handlePageChange = (event, page) => {
    this.setState({ page: page.activePage }, () => this.loadData());
  }

  handleSort = (field, direction) => {
    debugger;
    const { sortField } = this.state.sort;

    if (sortField !== field) {
      this.setState({
        sort: {
          ...this.state.sort,
          sortField: field,
          dir: 'asc'
        }
      }, () => this.loadData());
    } else if (sortField === field) {
      this.setState({
        sort: {
          ...this.state.sort,
          dir: !direction
            ? 'asc'
            : direction === 'asc' ? 'desc' : null

        }
      }, () => this.loadData());
    }
  }

  handleFilter = (fieldName, value) => {
    if (this.state.searchTimer) {
      clearTimeout(this.state.searchTimer);
    }

    const filter = { ...this.state.filter };

    const filterItemIndex = filter.filters.findIndex(item => item.property === fieldName);

    if (filterItemIndex < 0) {
      filter.filters.push({
        property: fieldName,
        value: value
      });
    } else {
      filter.filters[filterItemIndex].value = value;
    }

    this.setState({ filter: filter },
      () => this.setState({
        searchTimer: setTimeout(() => this.loadData(), 500)
      }));
  }
}

export default GridBase;