export const FETCH_RENDERED_DOCUMENTS_START = 'FETCH_RENDERED_DOCUMENTS_START';
export const FETCH_RENDERED_DOCUMENTS_SUCCESS = 'FETCH_RENDERED_DOCUMENTS_SUCCESS';
export const FETCH_RENDERED_DOCUMENTS_ERROR = 'FETCH_RENDERED_DOCUMENTS_ERROR';

export const FETCH_RENDERED_DOCUMENT_VERSIONS_START = 'FETCH_RENDERED_DOCUMENT_VERSIONS_START';
export const FETCH_RENDERED_DOCUMENT_VERSIONS_SUCCESS = 'FETCH_RENDERED_DOCUMENT_VERSIONS_SUCCESS';
export const FETCH_RENDERED_DOCUMENT_VERSIONS_ERROR = 'FETCH_RENDERED_DOCUMENT_VERSIONS_ERROR';

export const DOWNLOAD_RENDERED_DOCUMENT_VERSION_START = 'DOWNLOAD_RENDERED_DOCUMENT_VERSION_START';
export const DOWNLOAD_RENDERED_DOCUMENT_VERSION_SUCCESS = 'DOWNLOAD_RENDERED_DOCUMENT_VERSION_SUCCESS';
export const DOWNLOAD_RENDERED_DOCUMENT_VERSION_ERROR = 'DOWNLOAD_RENDERED_DOCUMENT_VERSION_ERROR';