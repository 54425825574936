import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import { Header } from 'semantic-ui-react';

import styles from './withErrorHandle.module.scss';

const withErrorHandler = (WrappedComponent, axios) => {
  class Wrapper extends Component {
    state = {
      errorMessage: null,
      error: false
    }

    componentWillMount() {
      this.reqInterceptor = axios.interceptors.request.use(req => {
        req.headers.Authorization = `Bearer ${this.props.user.access_token}`;

        this.setState({ error: null });
        return req;
      });

      this.resInterceptor = axios.interceptors.response.use(res => res, error => {
        if (!error || !error.response) {
          const errorMessage = error.message ? error.message : 'Something went wrong';
          this.setState({ errorMessage: errorMessage, error: true });

          return { errorMessage: errorMessage };
        }

        switch (+error.response.status) {
          case 401:
            this.setState({ errorMessage: 'Not Authorized', error: true });
            break;
          case 404:
            this.setState({ errorMessage: 'Not Found', error: true });
            break;
          case 504:
            this.setState({ errorMessage: 'Internal Server Error', error: true });
            break;
          case 500:
            this.setState({ errorMessage: error.response.data.message ? error.response.data.message : 'Something went wrong', error: true });
            break;
          default:
            this.setState({ errorMessage: 'Something went wrong', error: true });
            break;
        }

        throw error;
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.error === true && prevState.error !== this.state.error) {
        this.notify();
      }
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    handleClose = () => this.setState({ error: false, errorMessage: null });

    notify = () => {
      toast.error(<>
        <Header as='h3' className={styles.NotificationHeader}>Error</Header>
        <p>{this.state.errorMessage ? this.state.errorMessage : null}</p>
      </>, { autoClose: 10000, onClose: () => this.handleClose() });
    }

    render() {
      let wrappedCmp =
        <>
          <ToastContainer enableMultiContainer newestOnTop
            toastClassName={styles.NotificationToast}
            progressClassName={styles.NotificationProgressBar} />

          <WrappedComponent {...this.props} />
        </>;

      return wrappedCmp;
    }
  }

  const mapStateToProps = state => {
    return {
      user: state.oidc.user,
      isLoadingUser: state.oidc.isLoadingUser
    }
  }

  return connect(mapStateToProps, null)(Wrapper);
}

export default withErrorHandler;