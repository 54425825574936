import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RugAppsWidget } from 'rug-widgets';

import ToolbarMenu from '../../modules/Navigation/Toolbar/ToolbarMenu';

import * as constants from '../../shared/constants/constants';

import styles from './Layout.module.scss';

class Layout extends Component {
  state = { activeItem: '' };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    let layout = <>
      <ToolbarMenu
        activeItemChanged={this.handleItemClick}
        activeItem={this.state.activeItem}
        сurrentUser={this.props.currentUser} />
      <main className={styles.Content}>
        {this.props.children}
        {this.props.user ? <RugAppsWidget
          accessToken={this.props.user.access_token}
          spellbarConfigEndpointUrl={constants.SPELLBAR_CONFIG_ENDPOINT_URL} /> : null}
      </main>
    </>;

    return layout;
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  }
}

export default connect(mapStateToProps, null)(Layout);