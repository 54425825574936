import React from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

const loader = (props) => {
  return <Dimmer inverted active style={{ background: 'rgba(255,255,255,.65)' }}>
    <Loader size={props.size || 'large'} />
  </Dimmer>;
}

export default loader;