import React from 'react';

import { Menu, Segment } from 'semantic-ui-react';

import UserWidget from '../UserWidget/UserWidget';

import styles from './ToolbarMenu.module.scss';

const toolbarMenu = (props) => {
  let navItems = <></>;

  // if (props.user) {
  navItems = <>
    {/* <Menu.Item
      className={styles.MenuItem}
      name='regions'
      active={props.activeItem === 'regions'}
      onClick={props.activeItemChanged}
      as={NavLink} to='/administration/regions' exact activeClassName={styles.active}>
    </Menu.Item> */}
  </>
  // }

  return (
    <Segment className={styles.ToolbarSegment} inverted>
      <Menu className={styles.ToolbarMenu} inverted pointing secondary size='large'>
        <Menu.Item className={styles.LogoItem}>
          <a href="/" className={styles.logoLink}>
            <div className={styles.logo}></div>
          </a>
        </Menu.Item>
        {navItems}
        <div className={styles.grow}></div>
        <Menu.Item className={styles.VersionItem}>
          v{global.appVersion}
        </Menu.Item>
        <Menu.Item className={styles.AuthItem}>
          <UserWidget />
        </Menu.Item>
      </Menu>
    </Segment>
  );
}

export default toolbarMenu;