import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Table, Button, Icon, Input, Header, Checkbox, Dropdown } from 'semantic-ui-react';

import Paginator from '../Paginator/Paginator';
import Loader from '../Loader/Loader';

import styles from './ResourceViewer.module.scss';

let timeout = null;

export default class ResourceViewer extends Component {
  state = this.getInitialState();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data && this.props.data.entities ? this.props.data.entities : [] });
    }

    if (prevProps.showNewItemRow !== this.props.showNewItemRow) {
      this.setState({ newRow: {} });
    }
  }

  getInitialState() {
    return {
      newRow: {},
      data: this.props.data && this.props.data.entities ? this.props.data.entities : [],
      pageSize: 10,
      search: ''
    };
  }

  getNewRow = () => {
    let newRow = {};
    this.props.columns.forEach((x) => newRow[x.id] = "");
    return newRow;
  }

  renderEditColumn = (col, item) => {
    let colId = col.id;

    if (col.dataType === 'number') {
      return <Table.Cell key={col.id}>
        <Input
          style={{ width: '100%' }}
          type="number"
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    } else if (col.dataType === 'boolean') {
      return <Table.Cell key={col.id}>
        <Checkbox
          checked={item[colId] || false}
          onChange={(_, el) => {
            item[colId] = el.checked;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    } else if (col.dataType === 'single-select') {
      return <Table.Cell key={col.id}>
        <Dropdown
          placeholder={'Select ' + col.header}
          selection
          defaultValue={item[colId]}
          options={this.props.selectOptions}
          onChange={(_, el) => {
            item[colId] = el.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>
    } else {
      return <Table.Cell key={col.id}>
        <Input
          style={{ width: '100%' }}
          type='text'
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    }
  };

  renderColumn = (col, item) => {
    if (item.isEditMode && this.props.editMode) {
      return this.renderEditColumn(col, item);
    } else {
      return <Table.Cell key={col.id}>{col.accessor ? col.accessor(item) : item[col.id]}</Table.Cell>;
    }
  };

  tableLoader = () => <Loader />;

  renderNewItemRow = () => {
    let columns = this.props.columns.map((col) => {
      let newRow = this.state.newRow;

      if (col.dataType === 'number') {
        return <Table.Cell key={col.id}>
          <Input type="number"
            style={{ width: '100%' }}
            onChange={(e) => {
              if (Object.keys(newRow).length === 0) {
                newRow = this.getNewRow();
              }
              newRow[col.id] = e.target.value;
              this.setState({ newRow: newRow });
            }} />
        </Table.Cell>;
      } else if (col.dataType === 'boolean') {
        return <Table.Cell key={col.id}>
          <Checkbox
            onChange={(e, el) => {
              if (Object.keys(newRow).length === 0) {
                newRow = this.getNewRow();
              }
              newRow[col.id] = el.checked;
              this.setState({ newRow: newRow });
            }} />
        </Table.Cell>;
      } else if (col.dataType === 'single-select') {
        return <Table.Cell key={col.id}>
          <Dropdown
            placeholder={'Select ' + col.header}
            selection
            defaultValue={newRow[col.id]}
            options={this.props.selectOptions}
            onChange={(_, el) => {
              newRow[col.id] = el.value;
              this.forceUpdate();
            }}
          />
        </Table.Cell>
      } else {
        return <Table.Cell key={col.id}>
          <Input type="text"
            style={{ width: '100%' }}
            onChange={(e) => {
              if (Object.keys(newRow).length === 0) {
                newRow = this.getNewRow();
              }
              newRow[col.id] = e.target.value;
              this.setState({ newRow: newRow });
            }} />
        </Table.Cell>;
      }
    });

    if (this.props.editMode) {
      columns.push(<Table.Cell key="action">
        <Icon name='check' onClick={(e) => this.props.handleChange(this.state.newRow)} style={{ cursor: 'pointer' }} />
        <Icon name='close' onClick={(e) => {
          this.props.handleCancelAdd();
          this.setState({ newRow: {} })
        }} style={{ cursor: 'pointer' }} />
      </Table.Cell>);
    }

    if (this.props.selectionMode) {
      columns.unshift(<Table.Cell key="selectionCol">
        <div>
          <Input type="checkbox" value={this.state.newRow.checked} />
        </div>
      </Table.Cell>);
    }
    return <Table.Row>{columns}</Table.Row>;
  };

  renderRow = (item, i) => {
    let rows = [];

    let itemRow = <Table.Row key={item.id ? item.id : i}>
      {this.props.selectionMode && (
        <Table.Cell key={item.id ? item.id : i}>
          <div>
            <Input onChange={() => this.props.onItemCheck(item)} type="checkbox" checked={item.checked} />
          </div>
        </Table.Cell>
      )}

      {this.props.columns.map((col) => this.renderColumn(col, item))}

      {this.props.editMode
        ? (item.isEditMode
          ? <Table.Cell>
            <Icon name='check'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
                const { isEditMode, ...itemModel } = item;
                this.props.handleChange(itemModel);
              }} />
            <Icon name='close'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
              }}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>
          : <Table.Cell>
            <Icon name='edit'
              onClick={this.props.handleEditRow ? (e) => this.props.handleEditRow(e, item) : (e) => {
                item.isEditMode = true;
                this.forceUpdate();
              }}
              style={{ cursor: 'pointer' }} />
            <Icon name='close'
              onClick={this.props.handleRemoveRow ? (e) => this.props.handleRemoveRow(e, item) : (e) => this.props.handleRemove(item.id)}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>)
        : null}
    </Table.Row>;

    rows.push(itemRow);

    return rows;
  };

  renderHeaderRow = () => {
    return (
      <Table.Header>
        {this.props.displaySearch ? <Table.Row className={this.props.displaySearch ? styles.FilterRow : styles.FilterRowWithoutActions}>
          {this.props.columns.map(column => {
            return (
              <Table.HeaderCell
                key={column.id}
                width={column.width}>
                {column.search
                  ? <Input
                    className={styles.SearchInput}
                    placeholder='Search...'
                    value={this.props.filter && this.props.filter.filters && this.props.filter.filters.find(item => item.property === column.id)
                      ? this.props.filter.filters.find(item => item.property === column.id).value
                      : ''}
                    onChange={(event) => this.props.filterGrid(column.id, event.target.value)} />
                  : null}
              </Table.HeaderCell>)
          })}

          {this.props.editMode
            ? <Table.HeaderCell width='2'></Table.HeaderCell>
            : null}
        </Table.Row> : null}

        <Table.Row className={this.props.displaySearch ? styles.HeaderRow : styles.HeaderRowWithoutActions}>
          {this.props.columns.map(column => {
            return (
              <Table.HeaderCell
                key={column.id}
                width={column.width}
                style={{ cursor: column.sorting ? 'pointer' : 'default' }}
                onClick={column.sorting ? () => this.props.sort(column.id, this.props.dir, this.props.multipleSort) : null}>
                {column.header}
                <Icon name={
                  column.sorting
                    ? this.props.sortField === column.id
                      ? this.props.dir
                        ? this.props.dir === 'asc' ? 'sort up' : 'sort down'
                        : 'sort'
                      : 'sort'
                    : null
                } color='grey' />
              </Table.HeaderCell>
            )
          })}
          {this.props.editMode
            ? <Table.HeaderCell></Table.HeaderCell>
            : null}
        </Table.Row>
      </Table.Header>
    )
  };

  manageSorting = (columnId, orderBy) => {
    if (this.props.multipleSort) {
      const matches = this.props.sort.filter(s => s.includes(columnId));

      if (matches.length > 0) {
        let direction = matches[0].split(':').pop();
        return direction === 'asc' ? 'ascending' : 'descending';
      }
      else {
        return null;
      }
    } else {
      return this.props.sortBy === columnId ? (orderBy === 'asc' ? 'ascending' : 'descending') : null
    }
  }

  componentWillUnmount() {
    clearTimeout(timeout);
  };

  render() {
    let table = (
      <div className={styles.Container}>

        <div className={styles.TableHeaderActions} style={this.props.customTableHeaderStyles || null}>
          <span className={styles.TableHeader}>
            <Header as='h3' className={styles.headerText}>{this.props.header}</Header>
            <Icon name='refresh' onClick={this.props.refreshTable} className={styles.refreshBtn} />
          </span>

          {this.props.actions
            ? <Button className={styles.TableActionsButton} icon size='small' floated='right' labelPosition='left'
              as={Link} to={this.props.createResourceLink} exact="true">
              <Icon name='add' />
              Create
            </Button>
            : null}

          {this.props.editMode
            ? <Button type='button'
              className={styles.TableActionsButton}
              icon size='small' floated='right'
              labelPosition='left'
              onClick={this.props.handleAddNewRow} >
              <Icon name='add' />
              Add
            </Button>
            : null}
        </div>

        <Table celled={this.props.celled}
          selectable={this.props.selectable}
          sortable={this.props.sortable}
          striped={this.props.striped}
          className={styles.Table}>
          {this.renderHeaderRow()}

          {this.props.expandable
            ? this.props.renderExpandableTable(
              this.renderColumn,
              this.props.renderContentInfo,
              this.props.columns,
              this.props.accordionLoading,
              this.state.data,
              this.props.rowExpand,
              this.props.closeRow)
            : <Table.Body>
              {this.props.loading
                ? <Table.Row className={styles.tableRow}>
                  <Table.Cell style={{ position: 'relative', height: '70px' }} colSpan={this.props.editMode ? this.props.columns.length + 1 : this.props.columns.length}>
                    {this.tableLoader()}
                  </Table.Cell>
                </Table.Row>
                : null}

              {this.props.showNewItemRow && this.renderNewItemRow()}

              {!this.props.loading && this.state.data && this.state.data.length > 0
                ? this.state.data.map((item, i) => this.renderRow(item, i))
                : !this.props.loading
                  ? <Table.Row className={styles.tableRow}>
                    <Table.Cell colSpan={this.props.editMode ? this.props.columns.length + 1 : this.props.columns.length} textAlign='center'>
                      <p className={styles.noData}>No data to display</p>
                    </Table.Cell>
                  </Table.Row>
                  : null}
            </Table.Body>}

          {this.state.data && this.state.data && this.state.data.length && this.props.renderCustomFooter
            ? this.props.renderCustomFooter(
              this.props.columns,
              this.props.data.filteredCount,
              this.props.page,
              this.props.pageSize,
              this.props.pageChanged,
              this.props.shipment)
            : null}

          {this.props.pagination ? this.state.data && this.state.data && this.state.data.length
            ? <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={this.props.columns.length} className={styles.footerRow}>

                  <div className={styles.footerActions}>
                    {this.props.renderTotal ? this.props.renderTotal(this.props.data.filteredCount) : null}
                    <Paginator
                      floated='right'
                      size='small'
                      isPageSizeStatic={this.props.isPageSizeStatic}
                      filteredCount={this.props.data.filteredCount}
                      page={this.props.page}
                      pageSize={this.props.pageSize}
                      pageSizeChanged={this.props.pageSizeChanged}
                      pageChanged={this.props.pageChanged} />
                    {this.props.footerActions ? this.props.renderFooterActions() : null}
                  </div>
                </Table.HeaderCell>

                {this.props.editMode
                  ? <Table.HeaderCell></Table.HeaderCell>
                  : null}
              </Table.Row>
            </Table.Footer> : null : null}
        </Table>
      </div>
    );

    return table;
  }
}