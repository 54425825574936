import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './hoc/Layout/Layout';
import RenderedDocuments from './modules/RenderedDocuments/RenderedDocuments';

import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';

import axios from './axios/axios-api';

class App extends Component {
  render() {
    let content = <Layout></Layout>;

    if (this.props.user) {
      content = (
        <Layout>
          <Switch>
            <Route path="/renderedDocuments" component={RenderedDocuments} />
            <Route path="/" component={RenderedDocuments} />
          </Switch>
        </Layout>
      );
    }

    return content;
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  }
}

export default connect(mapStateToProps, null)(withErrorHandler(App, axios));